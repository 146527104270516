package it.neckar.bioexp.rest.message.opcua.demo

import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable
import kotlin.js.JsExport


@Serializable
@JsExport
/**
 * Request to set the shear stress - demo mode only
 */
data class ShearStressRequest(
  val shearStress: Double
): RestRequest

package it.neckar.projects.bioexp

import it.neckar.projects.common.Port
import it.neckar.projects.common.ServiceDescriptor

/**
 * Describes all services
 */
enum class BioExpService(
  override val serviceName: String,
  override val apiPrefix: String,
  override val port: Port,
  override val developmentState: ServiceDescriptor.DevelopmentState,
) : ServiceDescriptor {
  /**
   * The detection service
   */
  Detection("DetectionService", "api/detection", BioExPPorts.detectionService, ServiceDescriptor.DevelopmentState.InDevelopment),

  /**
   * Storage service
   */
  Storage("StorageService", "api/storage", BioExPPorts.storageService, ServiceDescriptor.DevelopmentState.InDevelopment),

  /**
   * Preannotation service
   */
  PreAnnotation("preAnnotation", "api/pre-annotation", BioExPPorts.preAnnotationService, ServiceDescriptor.DevelopmentState.InDevelopment),

  /**
   * Camera service
   */
  Camera("CameraService", "api/camera", BioExPPorts.cameraService, ServiceDescriptor.DevelopmentState.InDevelopment),

  /**
   * Opcua service
   */
  Opcua("OpcuaService", "api/opcua", BioExPPorts.opcuaService, ServiceDescriptor.DevelopmentState.InDevelopment);
}

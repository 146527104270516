package it.neckar.bioexp.rest.message.opcua.tank

import it.neckar.open.unit.quantity.Volume
import it.neckar.open.unit.si.ml
import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Request to set the tank volume
 */
data class TankVolumeRequest(
  val volume: @Volume @ml Double
): RestRequest

package it.neckar.ktor.client

import io.ktor.client.request.*
import it.neckar.common.featureflags.FeatureFlags
import it.neckar.common.featureflags.FeatureFlagsSupport
import it.neckar.rest.jwt.JwtToken
import it.neckar.rest.jwt.appendJwtTokenBearer


/**
 * Adds the header containing the feature flags (from [FeatureFlagsSupport.flags] to the request
 */
fun HttpRequestBuilder.featureFlagsHeader() {
  featureFlagsHeader(FeatureFlagsSupport.flags)
}

/**
 * Appends the feature flags to the header
 */
fun HttpRequestBuilder.featureFlagsHeader(flags: FeatureFlags) {
  header(FeatureFlags.HeaderKey, flags.encodeToString())
}

/**
 * Appends the JWT token as a header.
 *
 * Attention: Use the `io.ktor.server.auth.Authentication` plugin if possible!
 */
fun HttpRequestBuilder.appendJwtTokenBearer(jwtToken: JwtToken) {
  headers {
    this.appendJwtTokenBearer(jwtToken)
  }
}

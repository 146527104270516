package components

import components.login.LoginPage
import components.sop1.ResultsPageSOP1
import components.sop1.SOP1ImageSelectionPage
import components.cellcount.CellcountImageSelectionPage
import components.opcua.OpcuaPage
import components.sop2.ResultsPageSOP2
import components.sop2.SOP2ImageSelectionPage
import components.sop3.ResultsPageSOP3
import components.sop3.SOP3AlignmentPage
import components.sop3.SOP3ImageSelectionPage
import components.sop5.ResultsPageSOP5
import components.sop5.SOP5ImageSelectionPage
import it.neckar.react.common.*
import react.*
import services.UiActions
import store.ArrangingFilesSOP3
import store.DefaultStateSOP1
import store.DefaultStateSOP2
import store.DefaultStateSOP3
import store.DefaultStateSOP5
import store.DetectedStateSOP1
import store.DetectedStateSOP2
import store.DetectedStateSOP3
import store.DetectedStateSOP5
import store.OpcuaState
import store.UploadedStateSOP1
import store.UploadedStateSOP2
import store.UploadedStateSOP3
import store.UploadedStateSOP5
import store.useSelector
import web.file.File
import web.file.FileList

/**
 * main component that decides which page to show
 */
val BioExpMainComponent: FC<Props> = fc("BioExpMainComponent") { props ->
  val imageState = useSelector { imageState }
  val loginState = useSelector { loginState }
  val deploymentZone = useSelector { deploymentZone }
  val threshold = useSelector { thresholdState }

  //separator for cellcount
  if (deploymentZone.isCellcount()) {
    when (imageState) {
      DefaultStateSOP2 -> CellcountImageSelectionPage {}

      is UploadedStateSOP2 ->
        launchAndNotify {
          UiActions.runDetectionSOP2(imageState.filename, imageState.filepath, threshold.thresholdValue)
        }

      is DetectedStateSOP2 -> ResultsPageSOP2 {}

      else -> {
        LoadingPage {}
      }
    }
    return@fc
  }

  //login
  if (loginState.loggedIn.not()) {
    LoginPage {}
    return@fc
  }

  //SOP 1/2/3
  when (imageState) {
    OpcuaState -> OpcuaPage {}
    DefaultStateSOP1 -> SOP1ImageSelectionPage {}
    DefaultStateSOP2 -> SOP2ImageSelectionPage {}
    DefaultStateSOP3 -> SOP3ImageSelectionPage {}
    DefaultStateSOP5 -> SOP5ImageSelectionPage {}

    is ArrangingFilesSOP3 -> {
      SOP3AlignmentPage {
        attrs {
          files = imageState.filesToArrange
        }
      }
    }

    is DetectedStateSOP1 -> ResultsPageSOP1 {}
    is DetectedStateSOP2 -> ResultsPageSOP2 {}
    is DetectedStateSOP3 -> ResultsPageSOP3 {}
    is DetectedStateSOP5 -> ResultsPageSOP5 {}

    is UploadedStateSOP1 ->
      launchAndNotify {
        UiActions.runDetectionSOP1(imageState.filename, imageState.filepath, threshold.thresholdValue)
      }

    is UploadedStateSOP2 ->
      launchAndNotify {
        UiActions.runDetectionSOP2(imageState.filename, imageState.filepath, threshold.thresholdValue)
      }

    is UploadedStateSOP3 -> {
      launchAndNotify {
        UiActions.runDetectionSOP3(imageState.filepaths, imageState.filenames, threshold.thresholdValue)
      }
    }

    is UploadedStateSOP5 -> {
      launchAndNotify {
        UiActions.runDetectionSOP5(imageState.filename, imageState.filepath, threshold.thresholdValue)
      }
    }

    else -> {
      LoadingPage {}
    }
  }
}

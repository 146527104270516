package it.neckar.bioexp.rest.client

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import it.neckar.bioexp.rest.message.DetectRequest
import it.neckar.bioexp.rest.message.DetectResponseEC
import it.neckar.bioexp.rest.message.DetectResponseRBC
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.post
import it.neckar.ktor.client.safeTryFetch

/**
 * Client to access the bio exp services
 */
class DetectionServiceClient(val httpClient: HttpClient, val urlSupport: UrlSupport) {
  /**
   * Detects RBC
   * Calls object detection for the given image path.
   * The image has to be uploaded to the server before
   */
  suspend fun detectRBC(filePath: DetectRequest, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): DetectResponseRBC {
    return safeTryFetch(DetectResponseRBC.Failure) {
      httpClient
        .post(urlSupport.detection.detectRBC) {
          featureFlagsHeader()
          setBody(filePath)
          contentType(ContentType.Application.Json)
          additionalRequestConfiguration()
        }.body()
    }
  }

  /**
   * Detects EC
   * Calls object detection for the given image path.
   * The image has to be uploaded to the server before
   */
  suspend fun detectEC(filePath: DetectRequest, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): DetectResponseEC {
    return safeTryFetch(DetectResponseEC.Failure) {
      httpClient
        .post(urlSupport.detection.detectEC) {
          featureFlagsHeader()
          setBody(filePath)
          contentType(ContentType.Application.Json)
          additionalRequestConfiguration()
        }.body()
    }
  }

  /**
   * Detects RBC for SOP3
   * Calls object detection for the given image path.
   * The image has to be uploaded to the server before
   */
  suspend fun detectRBCSOP3(filePath: DetectRequest, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): DetectResponseRBC {
    return safeTryFetch(DetectResponseRBC.Failure) {
      httpClient
        .post(urlSupport.detection.detectRBCSOP3) {
          featureFlagsHeader()
          setBody(filePath)
          contentType(ContentType.Application.Json)
          additionalRequestConfiguration()
        }.body()
    }
  }

  /**
   * Detects Rouleaux for SOP5
   */
  suspend fun detectSOP5(filePath: DetectRequest, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): DetectResponseEC {
    return safeTryFetch(DetectResponseEC.Failure) {
      httpClient
        .post(urlSupport.detection.detectSOP5) {
          featureFlagsHeader()
          setBody(filePath)
          contentType(ContentType.Application.Json)
          additionalRequestConfiguration()
        }.body()
    }
  }
}

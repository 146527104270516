package it.neckar.bioexp.rest.client

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import it.neckar.bioexp.rest.message.CameraImageResponse
import it.neckar.bioexp.rest.message.CameraSettings
import it.neckar.bioexp.rest.message.CameraSettingsResponse
import it.neckar.bioexp.rest.message.opcua.demo.FlowSelectRequest
import it.neckar.bioexp.rest.message.opcua.demo.PumpStatusResponse
import it.neckar.bioexp.rest.message.opcua.demo.PurgeTimeRequest
import it.neckar.bioexp.rest.message.opcua.demo.ShearStressRequest
import it.neckar.bioexp.rest.message.opcua.tank.TankVolumeRequest
import it.neckar.bioexp.rest.message.opcua.tank.TankVolumeResponse
import it.neckar.bioexp.rest.message.opcua.temperature.CurrentTemperatureResponse
import it.neckar.bioexp.rest.message.opcua.temperature.StartTemperatureControlRequest
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.safeTryFetch
import it.neckar.ktor.client.*

/**
 * Client to access the OPC UA server
 */
class OpcuaClient(val httpClient: HttpClient, val urlSupport: UrlSupport) {
  suspend fun setTargetTemperature(targetTemperature: Double, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.setTemperature) {
        featureFlagsHeader()
        setBody(StartTemperatureControlRequest(targetTemperature))
        contentType(ContentType.Application.Json)
        additionalRequestConfiguration()
      }
  }

  suspend fun getCurrentTemperature(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): CurrentTemperatureResponse {
    return safeTryFetch(CurrentTemperatureResponse.Failure) {
      httpClient
        .get(urlSupport.opcua.getTemperature) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun getTargetTempFeedback(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): CurrentTemperatureResponse {
    return safeTryFetch(CurrentTemperatureResponse.Failure) {
      httpClient
        .get(urlSupport.opcua.getTargetTempFeedback) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun initTemperature(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.initTemperatureControl) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }
  }

  suspend fun initPumps(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.initPumpControl) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }
  }

  suspend fun getCurrentPumpStatus(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): PumpStatusResponse {
    return safeTryFetch(PumpStatusResponse.Failure) {
      httpClient
        .get(urlSupport.opcua.readPump1Status) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun startPump(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.startPump1) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }
  }

  suspend fun stopPump(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.stopPump1) {
        featureFlagsHeader()
        additionalRequestConfiguration()
      }
  }

  suspend fun setFlowSelect(flowSelect: Double, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.flowSelect) {
        featureFlagsHeader()
        setBody(FlowSelectRequest(flowSelect))
        contentType(ContentType.Application.Json)
        additionalRequestConfiguration()
      }
  }

  suspend fun setPurgeTime(purgeTime: Double, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.purgeTime) {
        featureFlagsHeader()
        setBody(PurgeTimeRequest(purgeTime))
        contentType(ContentType.Application.Json)
        additionalRequestConfiguration()
      }
  }

  suspend fun setShearStress(shearStress: Double, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.shearStress) {
        featureFlagsHeader()
        setBody(ShearStressRequest(shearStress))
        contentType(ContentType.Application.Json)
        additionalRequestConfiguration()
      }
  }

  suspend fun setTankVolume(volume: Double, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}) {
    httpClient
      .post(urlSupport.opcua.tankVolume) {
        featureFlagsHeader()
        setBody(TankVolumeRequest(volume))
        contentType(ContentType.Application.Json)
        additionalRequestConfiguration()
      }
  }

  suspend fun getTankVolume(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): TankVolumeResponse {
    return safeTryFetch(TankVolumeResponse.Failure) {
      httpClient
        .get(urlSupport.opcua.tankVolume) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }
}

package it.neckar.bioexp.rest.message.opcua.demo

import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Request to select the flow type - demo mode only
 */
data class FlowSelectRequest(
  val flowSelect: Double, //0 = ml/min, 1 = pa
):RestRequest

package it.neckar.bioexp.rest.message.opcua.demo

import it.neckar.open.unit.quantity.Time
import it.neckar.open.unit.si.ms
import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Request to set the purge time - demo mode only
 */
data class PurgeTimeRequest (
  val purgeTime: @Time @ms Double
): RestRequest

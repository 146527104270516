package it.neckar.bioexp.rest.message.opcua.pump

import it.neckar.bioexp.rest.model.pump.PumpConfiguration
import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Request to set the pump configuration
 */
data class PumpConfigurationRequest(
  val configuration: PumpConfiguration,
):RestRequest

package it.neckar.bioexp.rest.model.pump

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Represents the current state of a pump
 */
enum class PumpState {
  IDLE,
  RUNNING
}

package it.neckar.bioexp.rest.model.pump

import it.neckar.open.unit.quantity.Time
import it.neckar.open.unit.si.ms
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Represents the settings of a pump
 */
data class PumpConfiguration(
  val targetShearStress: Double,
  val currentShearStress: Double,
  val targetRpm: Int,
  val currentRpm: Int,
  val remainingTime: @Time @ms Double,
  val elapsedTime: @Time @ms Double,
)

package it.neckar.bioexp.rest.message.opcua.temperature

import it.neckar.open.unit.quantity.Temperature
import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Request to start the temperature control
 */
data class StartTemperatureControlRequest(
  val targetTemperature: @Temperature Double,
) : RestRequest

package it.neckar.projects.bioexp

/**
 * The supported hostnames for BioExP
 */
enum class BioExPHost(val hostname: String, val role: Role) {
  Localhost("localhost", Role.Cloud),
  //LocalhostWithCamera("localhost", Role.OnPremise),

  Playground("playground.bioexp.neckar.it", Role.Cloud),
  //PlaygroundAI("playground.bioexp.neckar.ai"),

  CellCount("cellcount.neckar.it", Role.Cloud),
  //CellCountAI("cellcount.neckar.ai"),

  Production("bioexp.neckar.it", Role.Cloud),
  //ProductionAI("bioexp.neckar.ai"),
  ;

  enum class Role {

    /**
     * In the cloud, no camera and Opc/Ua
     */
    Cloud,

    /**
     * On premise, including camera and Opc/Ua
     */
    OnPremise,
  }
}

package it.neckar.bioexp.rest.model.temperature

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Represents the state of a heating process
 */
data class HeatingProcessState(
  val state: HeatingState,
  val targetTemperature: Double,
  val currentTemperature: Double,
  val remainingTime: Double,
  val elapsedTime: Double,
)

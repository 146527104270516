package it.neckar.bioexp.rest.message.opcua.temperature

import it.neckar.bioexp.rest.model.temperature.MeasuredTemperature
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents the response for the current temperature
 */
@Serializable
sealed interface CurrentTemperatureResponse : RestResponse<MeasuredTemperature> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: MeasuredTemperature) : CurrentTemperatureResponse, RestResponse.SuccessResponse<MeasuredTemperature> {
    val temperature: MeasuredTemperature
      get() = data
  }

  @Serializable
  @SerialName("failure")
  data object Failure : CurrentTemperatureResponse, RestResponse.FailureResponse<MeasuredTemperature>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: MeasuredTemperature): CurrentTemperatureResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): CurrentTemperatureResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: MeasuredTemperature?): CurrentTemperatureResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}

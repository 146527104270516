package it.neckar.bioexp.rest.model.pump

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Represents the state of a pumping process
 * @param state the state of the pump
 * @param settings the settings of the pump
 */
data class PumpProcessState(
  val state: PumpState,
  val settings: PumpConfiguration
)

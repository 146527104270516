package it.neckar.bioexp.rest

import it.neckar.commons.js.CookieName
import it.neckar.open.http.Url
import it.neckar.open.http.UrlParameterName
import it.neckar.rest.jwt.JwtCookies

/**
 * Contains all constants for the BioExP API
 */
object BioExPApiConstants {
  private val basePath: Url.Relative = Url.relative("api")

  /**
   * Contains the cookie names
   */
  object Cookies {
    /**
     * Cookie that contains the JWS access token
     */
    val accessToken: CookieName = JwtCookies.accessToken
  }

  /**
   * Contains constants for parameters
   */
  object Parameters {
    val uuid: UrlParameterName = UrlParameterName("uuid")
    val pumpId: UrlParameterName = UrlParameterName("pumpId")
    val tankId: UrlParameterName = UrlParameterName("tankId")
  }

  /**
   * Constants for the detection service
   */
  object Detection {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "detection"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val detectRBC: Url.Relative = basePath + "detect-rbc"
    val detectEC: Url.Relative = basePath + "detect-ec"
    val detectSOP3: Url.Relative = basePath + "detect-sop3"
    val detectSOP5: Url.Relative = basePath + "detect-sop5"
  }

  /**
   * Constants for the storage service
   */
  object Storage {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "storage"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    /**
     * Path to upload a file
     */
    val uploadFile: Url.Relative = basePath + "upload"

    val uploadFeedback: Url.Relative = basePath + "upload-feedback"
  }

  /**
   * Constants for the pre-annotation service
   */
  object PreAnnotation {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "annotations"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val predict: Url.Relative = basePath + "predict"
    val health: Url.Relative = basePath + "health"
    val setup: Url.Relative = basePath + "setup"
    val metrics: Url.Relative = basePath + "metrics"
  }

  object Camera {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "camera"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val capture: Url.Relative = basePath + "capture"
    val metrics: Url.Relative = basePath + "metrics"
    val settings: Url.Relative = basePath + "settings"
  }

  /**
   * Constants for the OPC/UA service
   *
   * - emergency stop
   * - temperature
   *
   *
   */
  object Opcua {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "process"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val browseRoot: Url.Relative = basePath + "browse-root"

    val emergencyStop: Url.Relative = basePath + "emergency-stop"
    val temperature: Url.Relative = basePath + "temperature"
    val startTemperatureControl: Url.Relative = temperature + "start"
    val cancelTemperatureControl: Url.Relative = temperature + "cancel"

    val pumps: Url.Relative = basePath + "pumps"
    val pump: Url.Relative = pumps + Parameters.pumpId.toString()
    val pumpConfiguration: Url.Relative = pump + "config"
    val startPumpProcess: Url.Relative = pump + "start"
    val cancelPumpProcess: Url.Relative = pump + "cancel"
    val pumpProcessState: Url.Relative = pump + "state"
    val tanks: Url.Relative = basePath + "tanks"
    val tankVolume: Url.Relative = tanks + "$/{${Parameters.tankId}}/volume"
    val tank1Volume: Url.Relative = tanks + "4bd37ebc-0097-49c5-937e-bc009789c58f/volume"


    val demo: Url.Relative = basePath + "demo"

    val initTemperature: Url.Relative = demo + "init-temperature"
    val initPumps: Url.Relative = demo + "init-pumps"
    val flowSelect: Url.Relative = demo + "flow-select"
    val purgeTime: Url.Relative = demo + "purge-time"
    val shearStress: Url.Relative = demo + "shear-stress"
    val startPump1: Url.Relative = demo + "start"
    val readPump1Status: Url.Relative = demo + "read-pump1-status"
    val stopPump1: Url.Relative = demo + "stop"
    val setTemperature: Url.Relative = demo + "set-temperature"
    val getTemperature: Url.Relative = demo + "get-temperature"
    val targetTempFeedback: Url.Relative = demo + "target-temp-feedback"
  }
}

package components.opcua

import it.neckar.open.kotlin.lang.toBase64
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.classes
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.lang
import react.*
import react.dom.*
import services.UiActions
import styled.*

/**
 * main page where the image for SOP1 can be selected
 */
val OpcuaPage: FC<Props> = fc("OpcUaPage") { props ->

  val (imageData, setImageData) = useState("")

  val (targetTemp, setTargetTemp) = useState(0.0)
  val (flowSelect, setFlowSelect) = useState(0.0)
  val (stressSelect, setCurrentStressSelect) = useState(0.0)
  val (purgeTime, setPurgeTime) = useState(0.0)
  val (targetTankVolume, setTargetTankVolume) = useState(0.0)

  val (currentTemp, setCurrentTemp) = useState(0.0)
  val (targetTempFeedback, setTargetTempFeedback) = useState(0.0)
  val (currentPumpStatus, setCurrentPumpStatus) = useState(0.0)
  val (currentTankVolume, setcurrentTankVolume) = useState(0.0)

  div("container-fluid text-white") {
    div("container pt-5 py-3") {

      div("column") {

        div("text-center fs-1 d-flex justify-content-between align-items-center") {
          actionButton(
            classes = "btn btn-primary",
            icon = ButtonIcon.centered(FontAwesomeIcons.arrowLeft),
            action = suspend { UiActions.setSOP3DefaultState() }
          ) { }

          div("fw-bold my-0") {
            +"Opcua Debug Page"
          }

          //empty div for center text and left aligned back-button
          div { }
        }

        div("row mt-5") {
          //div() {
          //  actionButton(
          //    classes = "btn btn-primary",
          //    icon = ButtonIcon.centered(FontAwesomeIcons.magnifyingGlass),
          //    action = suspend {
          //      setSettings(UiActions.getSettings()!!)
          //    }
          //  ) { +" Fetch Settings" }
          //
          //  div("mt-2") {
          //    p {
          //      +"FPS: ${settings.fps}"
          //    }
          //    p {
          //      +"Auto Exposure: ${settings.autoExposure}"
          //    }
          //    p {
          //      +"Exposure Time: ${settings.exposureTime}"
          //    }
          //    p {
          //      +"Frames to Capture: ${settings.framesToCapture}"
          //    }
          //  }
          //}

          div("mt-3") {
            actionButton(
              classes = "btn btn-primary",
              icon = ButtonIcon.centered(FontAwesomeIcons.magnifyingGlass),
              action = suspend {
                setImageData(UiActions.captureImage()!!.imageData.toBase64())
              }
            ) { +" Capture Image" }

            div("mt-3") {
              styledDiv {
                css {
                  resize = Resize.both
                  overflow = Overflow.auto
                }
                attrs.classes += "border border-1 border-dark d-flex"
                img(src = imageData, classes = "flex-fill mw-100") {
                  attrs {
                    width = "100%"
                    height = "100%"
                  }
                }
              }
            }
          }


          div("mt-5 row") {
            div("col-sm-4") {
              actionButton(
                classes = "btn btn-primary",
                icon = ButtonIcon.centered(FontAwesomeIcons.magnifyingGlass),
                action = suspend {
                  setTargetTempFeedback(UiActions.getTargetTempFeedback())
                  setCurrentTemp(UiActions.getCurrentTemp())
                  setCurrentPumpStatus(UiActions.getCurrentPumpStatus())
                  setcurrentTankVolume(UiActions.getCurrentTankVolume())
                }
              ) { +" Fetch settings" }

              div("mt-2") {
                p {
                  +"[T_RI_W] Target Temp: $targetTemp"
                }
                p {
                  +"[T_RI_W_FB] Target Temp Feedback: $targetTempFeedback"
                }
                p {
                  +"[T_RI_X] Current Temp: $currentTemp"
                }
                p{
                  +"[PUMP_X1] Current Pump Status: $currentPumpStatus"
                }
                p {
                  +"[SET_TANK_VOL1] Target Tank Volume: $targetTankVolume"
                }
                p{
                  +"[STAT_TANK_VOL1] Current Tank Volume: $currentTankVolume"
                }
              }

              actionButton(
                classes = "btn btn-primary mt-3",
                icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
                action = suspend {
                  UiActions.initTemperature()
                  UiActions.initPumps()
                }
              ) { +" Initialize Pump and Temperature" }
              div{
                p{
                  +"THERMOS_STATUS = 1"
                }
                p{
                  +"THERMOS_ONOFF =1"
                }
                p{
                  +"T_REGLER_ONOFF=1"
                }
                p{
                  +"PUMP_STATUS=1"
                }
                p{
                  +"SET_PUMP_CA=1"
                }
              }
            }

            div("col-sm-4") {
                h5 {
                  +"Temperature"
                }

                div("mt-3 form-outline col-sm-6") {
                  label("form-label") {
                    attrs["htmlFor"] = "temp"
                    +"Target Temperature [T_RI_W]"
                  }
                  input(classes = "form-control") {
                    attrs {
                      id = "temp"
                      type = InputType.number
                      lang = "en"
                      defaultValue = "20.0"
                      max = "100.0"
                      min = "0.0"
                      step = "0.5"
                      onChangeFunction = { event ->
                        val temp = event.target.asDynamic().value.unsafeCast<Double>()
                        setTargetTemp(temp)
                      }
                    }
                  }

                  actionButton(
                    classes = "btn btn-primary",
                    icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
                    action = suspend {
                      UiActions.setTargetTemp(targetTemp)
                    }
                  ) { +" Set" }
                }
              }

            div("col-sm-4") {
              h5 {
                +"Pump"
              }

              actionButton(
                classes = "btn btn-primary",
                icon = ButtonIcon.centered(FontAwesomeIcons.add),
                action = suspend {
                  UiActions.startPump1()
                }
              ) { +" Start Pump 1 [PUMP_ON1=1]" }

              actionButton(
                classes = "btn btn-primary",
                icon = ButtonIcon.centered(FontAwesomeIcons.minus),
                action = suspend {
                  UiActions.stopPump1()
                }
              ) { +" Stop Pump 1 [PUMP_ON1=0]" }

              div("mt-3 form-outline col-sm-6") {
                label("form-label") {
                  attrs["htmlFor"] = "tank"
                  +"Target Tank Volume in ml [SET_TANK_VOL1]"
                }
                input(classes = "form-control") {
                  attrs {
                    id = "tank"
                    type = InputType.number
                    lang = "en"
                    defaultValue = "0"
                    max = "500.0"
                    min = "0.0"
                    step = "1"
                    onChangeFunction = { event ->
                      val volume = event.target.asDynamic().value.unsafeCast<Double>()
                      setTargetTankVolume(volume)
                    }
                  }
                }

                actionButton(
                  classes = "btn btn-primary",
                  icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
                  action = suspend {
                    UiActions.setTankVolume(targetTankVolume)
                  }
                ) { +" Set" }
              }


              div("mt-3 form-outline col-sm-6") {
                label("form-label") {
                  attrs["htmlFor"] = "flow"
                  +"Flow Select 0=ml/min 1=Pa [FLOW_SELECT]"
                }
                input(classes = "form-control") {
                  attrs {
                    id = "flow"
                    type = InputType.number
                    lang = "en"
                    defaultValue = "0.0"
                    max = "1.0"
                    min = "0.0"
                    step = "1"
                    onChangeFunction = { event ->
                      val flow = event.target.asDynamic().value.unsafeCast<Double>()
                      setFlowSelect(flow)
                    }
                  }
                }

                actionButton(
                  classes = "btn btn-primary",
                  icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
                  action = suspend {
                    UiActions.setFlowSelect(flowSelect)
                  }
                ) { +" Set" }
              }

              div("mt-3 form-outline col-sm-6") {
                label("form-label") {
                  attrs["htmlFor"] = "stress"
                  +"Set shear stress (Pa) [SET_TAU_W_PURG]"
                }
                input(classes = "form-control") {
                  attrs {
                    id = "stress"
                    type = InputType.number
                    lang = "en"
                    defaultValue = "0.0"
                    max = "30.0"
                    min = "0.0"
                    step = "0.1"
                    onChangeFunction = { event ->
                      val stress = event.target.asDynamic().value.unsafeCast<Double>()
                      setCurrentStressSelect(stress)
                    }
                  }
                }

                actionButton(
                  classes = "btn btn-primary",
                  icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
                  action = suspend {
                    UiActions.setShearStress(stressSelect)
                  }
                ) { +" Set" }
              }

              div("mt-3 form-outline col-sm-6") {
                label("form-label") {
                  attrs["htmlFor"] = "purge"
                  +"Set purge time (seconds) [PURGE_TIME]"
                }
                input(classes = "form-control") {
                  attrs {
                    id = "purge"
                    type = InputType.number
                    lang = "en"
                    defaultValue = "0.0"
                    max = "60.0"
                    min = "0.0"
                    step = "1"
                    onChangeFunction = { event ->
                      val time = event.target.asDynamic().value.unsafeCast<Double>()
                      setPurgeTime(time)
                    }
                  }
                }

                actionButton(
                  classes = "btn btn-primary",
                  icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
                  action = suspend {
                    UiActions.setPurgeTime(purgeTime)
                  }
                ) { +" Set" }
              }

            }
          }
        }
      }
    }
  }
}

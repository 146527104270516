@file:UseSerializers(UuidSerializer::class)
package it.neckar.bioexp.rest.model.pump

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import it.neckar.uuid.UuidSerializer
import it.neckar.uuid.randomUuid4
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlin.js.JsExport
import kotlin.jvm.JvmInline

@JsExport
@Serializable
/**
 * Represents a Pump
 */
data class Pump(
  val id: Id,
  val name: String,
  val processState: PumpProcessState
) {
  @JsExport.Ignore
  @Serializable
  @JvmInline
  value class Id(val value: Uuid) {
    override fun toString(): String {
      return value.toString()
    }

    companion object {
      fun random(): Id = Id(randomUuid4())
    }
  }

  companion object {
    fun create(name: String, processState: PumpProcessState): Pump {
      return Pump(Id.random(), name, processState)
    }

    val pump1Id = Id(uuidFrom("894d37ea-4767-4bf8-8d37-ea47670bf857"))
    val pump2Id = Id(uuidFrom("1a6863c1-3635-4f5a-a863-c136350f5a91"))
    val pump3Id = Id(uuidFrom("5a331c49-bec0-4fb3-b31c-49bec01fb358"))
    val pump4Id = Id(uuidFrom("7d73f165-9c58-4efd-b3f1-659c585efdb4"))

    val pumps = listOf(pump1Id, pump2Id, pump3Id, pump4Id)
  }
}

package it.neckar.bioexp.rest.model.temperature

import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Represents the current state of a heating element
 */
enum class HeatingState {
  IDLE,
  STARTUP,
  MAINTAINING
}

package services

import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import it.neckar.bioexp.rest.client.CameraClient
import it.neckar.bioexp.rest.client.DeploymentZone
import it.neckar.bioexp.rest.client.DetectionServiceClient
import it.neckar.bioexp.rest.client.OpcuaClient
import it.neckar.bioexp.rest.client.StorageServiceClient
import it.neckar.bioexp.rest.client.UrlSupport
import it.neckar.bioexp.rest.io.bioExpJsonConfiguration
import it.neckar.ktor.client.createHttpClient
import it.neckar.ktor.client.installProtocolVersionHeader
import kotlinx.serialization.json.Json

object UiServices {
  private var _urlSupport: UrlSupport? = null

  val urlSupport: UrlSupport
    get() = _urlSupport ?: throw IllegalStateException("Not initialized. Call initialize()")


  fun initialize(deploymentZone: DeploymentZone.Client) {
    if (this._urlSupport != null) {
      //The url support is used by reference. It is not possible to reinitialize at the moment
      throw IllegalStateException("Already initialized")
    }

    this._urlSupport = UrlSupport.forClient(deploymentZone)
  }


  /**
   * HTTP client without authorization.
   * Is used for login
   */
  val httpClientNoAuth: HttpClient = createHttpClient("httpClientNoAuth") {
    expectSuccess = true

    installProtocolVersionHeader()

    install(ContentNegotiation) {
      json(Json {
        bioExpJsonConfiguration()
      })
    }
  }

  val detectionServiceClient: DetectionServiceClient by lazy {
    DetectionServiceClient(httpClientNoAuth, urlSupport)
  }

  val storageServiceClient: StorageServiceClient by lazy {
    StorageServiceClient(httpClientNoAuth, urlSupport)
  }

  val cameraService: CameraClient by lazy {
    CameraClient(httpClientNoAuth, urlSupport)
  }

  val opcuaClient: OpcuaClient by lazy {
    OpcuaClient(httpClientNoAuth, urlSupport)
  }
}

package components.sop1

import components.table.CellTableRow
import components.table.ResultsTable
import it.neckar.bioexp.rest.detect.CellType
import it.neckar.bioexp.rest.detect.DetectedCell
import it.neckar.geometry.Rectangle
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import kotlinx.css.*
import kotlinx.html.classes
import react.*
import react.dom.*
import services.UiActions
import store.DetectedStateSOP1
import store.useSelector
import styled.*

/**
 * page that shows the results for SOP1 after the detection is done
 *
 * x = (RBCges - RBCnoec) / EC
 */
val ResultsPageSOP1: FC<Props> = fc("ResultsPageSOP1") { props ->

  val imageState = useSelector { imageState }
  require(imageState is DetectedStateSOP1)

  /**
   * calculate the intersection coefficient between RBC bounding box !center! and EC bitmap
   */
  //val centerIntersectionCoefficient: Double = useMemo(imageState) {
  //  val detections = imageState.RBCetectionResult.detectedObjects
  //  val ecBitmap = imageState.ecBitmap
  //
  //  val rbcEcOverlapCenter = mutableListOf<DetectedCell>()
  //
  //  detections.forEach { detectedCell ->
  //    //convert relative bounding box to absolute bounding box using bitmap dimensions as image dimensions
  //
  //    val imageWidth = ecBitmap.width
  //    val imageHeight = ecBitmap.height
  //
  //    val topLeftXAbsolute = (detectedCell.boundingBox.getX() * imageWidth).toInt()
  //    val topLeftYAbsolute = (detectedCell.boundingBox.getY() * imageHeight).toInt()
  //    val widthAbsolute = (detectedCell.boundingBox.getWidth() * imageWidth).toInt()
  //    val heightAbsolute = (detectedCell.boundingBox.getHeight() * imageHeight).toInt()
  //
  //    val absBoundingBox = Rectangle(topLeftXAbsolute.toDouble(), topLeftYAbsolute.toDouble(), widthAbsolute.toDouble(), heightAbsolute.toDouble())
  //
  //    //only check center pixel
  //    if (ecBitmap[absBoundingBox.centerX.toInt(), absBoundingBox.centerY.toInt()] == 1) {
  //      rbcEcOverlapCenter.add(detectedCell)
  //      println("cell ${detectedCell.cellType.name} ${detections.indexOf(detectedCell)} intersects with EC at ${absBoundingBox.centerX}, ${absBoundingBox.centerY}")
  //    }
  //  }
  //
  //  //RBCtotal - rbcnoec / EC
  //  val rbcTotal = detections.size
  //  val rbcNoEcCountCenter = rbcTotal - rbcEcOverlapCenter.size
  //
  //  val rbcOnEcCenter = ((rbcTotal - rbcNoEcCountCenter) / imageState.ECDetectionResult.detectedObjects.size.toDouble() * 10000).toInt() / 10000.0
  //  rbcOnEcCenter
  //}

  /**
   * calculate the intersection coefficient between RBC bounding box !any pixel! and EC bitmap
   */
  //val pixelsIntersectionCoefficient: Double = useMemo(imageState) {
  //  val detections = imageState.RBCetectionResult.detectedObjects
  //  val ecBitmap = imageState.ecBitmap
  //  val rbcEcOverlapCenter = mutableListOf<DetectedCell>()
  //  val rbcEcOverlapPixel = mutableListOf<DetectedCell>()
  //
  //  detections.forEach { detectedCell ->
  //    //convert relative bounding box to absolute bounding box using bitmap dimensions as image dimensions
  //
  //    val imageWidth = ecBitmap.width
  //    val imageHeight = ecBitmap.height
  //
  //    val topLeftXAbsolute = (detectedCell.boundingBox.getX() * imageWidth).toInt()
  //    val topLeftYAbsolute = (detectedCell.boundingBox.getY() * imageHeight).toInt()
  //    val widthAbsolute = (detectedCell.boundingBox.getWidth() * imageWidth).toInt()
  //    val heightAbsolute = (detectedCell.boundingBox.getHeight() * imageHeight).toInt()
  //
  //    //check for intersections with EC bitmap, loop over all pixels in bitmap
  //    for (x in topLeftXAbsolute until topLeftXAbsolute + widthAbsolute) {
  //      for (y in topLeftYAbsolute until topLeftYAbsolute + heightAbsolute) {
  //        if (ecBitmap[x, y] == 1 && rbcEcOverlapPixel.contains(detectedCell).not()) {
  //          rbcEcOverlapPixel.add(detectedCell)
  //          println("cell ${detectedCell.cellType.name} ${detections.indexOf(detectedCell)} intersects with EC at $x, $y")
  //        }
  //      }
  //    }
  //  }
  //
  //  //RBCtotal - rbcnoec / EC
  //  val rbcTotal = detections.size
  //  val rbcNoEcCountPixel = rbcTotal - rbcEcOverlapPixel.size
  //
  //  val rbcOnEcPixel = ((rbcTotal - rbcNoEcCountPixel) / imageState.ECDetectionResult.detectedObjects.size.toDouble() * 10000).toInt() / 10000.0
  //  rbcOnEcPixel
  //}


  div("container-fluid text-white") {
    div("container pt-5 py-3") {

      div("row") {

        div("text-center fs-1 d-flex justify-content-between align-items-center") {
          actionButton(
            classes = "btn btn-primary",
            icon = ButtonIcon.centered(FontAwesomeIcons.arrowLeft),
            action = suspend { UiActions.setSOP1DefaultState() }
          ) { }

          div("fw-bold my-0") {
            +"Detection results for ${imageState.filename}"
          }
          //empty div for center text and left aligned back-button
          div { }
        }

        div("mt-3") {
          val rows: MutableList<CellTableRow> = mutableListOf(CellTableRow("EC", imageState.ECDetectionResult.detectedObjects.size))

          ResultsTable {
            attrs {
              tableRows = rows
            }
          }
        }
      }


      /**
       * rbc debug image
       */
      styledDiv {
        css {
          resize = Resize.both
          overflow = Overflow.auto
        }
        attrs.classes += "border border-1 border-dark d-flex"
        img(src = imageState.imageDataRBC, classes = "flex-fill mw-100") {
          attrs {
            width = "100%"
            height = "100%"
          }
        }
      }

      /**
       * ec debug image
       */
      div("mt-3") {
        styledDiv {
          css {
            resize = Resize.both
            overflow = Overflow.auto
          }
          attrs.classes += "border border-1 border-dark d-flex"
          img(src = imageState.bitmapDrawnImage, classes = "flex-fill mw-100") {
            attrs {
              width = "100%"
              height = "100%"
            }
          }
        }
      }

      /**
       * ec debug image
       */
      div("mt-3") {
        styledDiv {
          css {
            resize = Resize.both
            overflow = Overflow.auto
          }
          attrs.classes += "border border-1 border-dark d-flex"
          img(src = imageState.imageDataEC, classes = "flex-fill mw-100") {
            attrs {
              width = "100%"
              height = "100%"
            }
          }
        }
      }
    }
  }
}

package it.neckar.bioexp.rest.message.opcua.pump

import it.neckar.open.unit.quantity.Time
import it.neckar.open.unit.si.ms
import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
/**
 * Request to start the pump
 * @param shearStress the shear stress to apply
 * @param duration the duration of the pump
 */
data class StartPumpRequest(
  val shearStress: Double,
  val duration: @Time @ms Double,
): RestRequest

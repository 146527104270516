package it.neckar.bioexp.rest.message.opcua.tank

import it.neckar.bioexp.rest.model.pump.Tank
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents the response for a tank volume
 */
@Serializable
sealed interface TankVolumeResponse : RestResponse<Double>{
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(override val data: Double) : TankVolumeResponse, RestResponse.SuccessResponse<Double> {
    val volume: Double
      get() = data
  }

  @Serializable
  @SerialName("failure")
  data object Failure : TankVolumeResponse, RestResponse.FailureResponse<Double>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: Double): TankVolumeResponse = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): TankVolumeResponse = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: Double?): TankVolumeResponse {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }
}
